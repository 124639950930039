section.sectionWrapper {
  margin-bottom: 0;
  margin-top: 80px;
}

@media screen and (--mobile) {
  .title {
    --typography-heading-5-font-size: var(--typography-heading-4-font-size);
    --typography-heading-5-line-height: var(--typography-heading-4-line-height);

    font-size: 20px;
  }

  .imageGroup {
    > div {
      display: none;
    }

    :nth-child(-n + 4) {
      display: block;
    }
  }
}
